import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Section from '../components/lib/Section'
import Container from '../components/lib/Container'
import Layout from '../components/Layout'
import PortfolioCard from '../components/lib/PortfolioCard'
import breakpoints from './../commons/breakpoints'
import Contact from '../components/Contact'

const StyledPortfolio = styled(Section)`
  background: ${({ theme }) => theme.colors.copyLight};
  padding-bottom: 0;
`

const PageTitle = styled.h1`
  margin-top: 1rem;

  @media ${breakpoints.device.tablet} {
    margin-top: 2rem;
  }
`

const Paragraph = styled.p`
  max-width: 40rem;
`
const Projects = styled.ul`
  margin-top: 3rem;
`

const Portfolio = ({ data }) => {
  return (
    <Layout pageTitle={`Portfolio | ${data.site.siteMetadata.title}`}>
      <StyledPortfolio>
        <Container>
          <PageTitle>Portfolio</PageTitle>
          <Paragraph>
            Succeed online with a mobile app that is fast, easy to use, and built with best practices and a passion for design. Here are some of my works.
          </Paragraph>
        </Container>

        <Projects>
          {data.allMdx.nodes.map((project, index) => (
            <PortfolioCard key={project.id} project={project} index={index} />
          ))}
        </Projects>
      </StyledPortfolio>
      <Contact />
    </Layout>
  )
}

export default Portfolio

export const query = graphql`
  query PortfolioQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { frontmatter: { type: { eq: "project" } } }) {
      nodes {
        frontmatter {
          role
          title
          category
          link
          techStack
          textColor
          bgColor
          firstScreen {
            childImageSharp {
              gatsbyImageData
            }
          }
          secondScreen {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        body
      }
    }
  }
`
