import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import FlexContainer from './FlexContainer'
import breakpoints from './../../commons/breakpoints'
import Container from './Container'
import checkmathBg from '../../images/checkmath-bg.svg'

const StyledPortfolioCard = styled.li`
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  min-height: 100vh;
  display: flex;
  padding: 2.5rem 0;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ title }) =>
    title === 'CheckMath' ? `url(${checkmathBg})` : 'none'};
  background-repeat: no-repeat;
  background-position: bottom center;

  & a,
  h2,
  h3 {
    color: ${({ textColor }) => textColor};
  }

  @media ${breakpoints.device.tablet} {
    padding: 4rem 0;
  }
`

const CardInner = styled(FlexContainer)`
  @media ${breakpoints.device.tablet} {
    & .screens {
      order: ${({ even }) => (even ? '2' : '1')};
    }

    & .desc {
      order: ${({ even }) => (even ? '1' : '2')};
    }
  }
`

const AppScreens = styled.div`
  display: flex;
  padding: 3rem;
  align-items: center;
  max-width: 450px;

  @media ${breakpoints.device.tablet} {
    flex-basis: 50%;
    max-width: 600px;
    padding: 4rem;
  }
`

const ScreenOneBox = styled.div`
  z-index: 2;
  transition: 0.5s;
  order: 1;
`

const ScreenTwoBox = styled.div`
  order: 2;
  margin-left: -3rem;
  transition: 0.5s;
  z-index: 1;

  &:hover {
    z-index: 3;
    transform: scale(1.2);
  }

  &:hover + div {
    transform: scale(0.84);
  }
`

const DescBox = styled.div`
  flex-basis: 50%;

  & .highlight {
    font-size: 1.1rem;
    font-weight: 400;
  }

  & .role {
    margin-top: 1rem;
  }
`

const DescHeader = styled.div`
  display: flex;
  flex-direction: column;
  & h2 {
    order: 2;
  }

  & p {
    order: 1;
    font-weight: 700;
  }
`

const Stack = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & h3 {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`

const Tool = styled.li`
  display: inline-block;
  margin-right: 0.5rem;
`

const PortfolioCard = ({ project, index }) => {
  const {
    role,
    title,
    category,
    // link,
    techStack,
    firstScreen,
    secondScreen,
    textColor,
    bgColor,
  } = project.frontmatter

  const screenOne = getImage(firstScreen)
  const screenTwo = getImage(secondScreen)

  const isEvenIndex = (1 + index) % 2 === 0
  const isLastIndex = (arr, index) => arr.length - 1 === index

  return (
    <StyledPortfolioCard {...{ textColor, bgColor, title }}>
      <Container>
        <CardInner even={isEvenIndex}>
          <AppScreens className='screens'>
            <ScreenTwoBox>
              <GatsbyImage image={screenTwo} alt={title} />
            </ScreenTwoBox>
            <ScreenOneBox>
              <GatsbyImage image={screenOne} alt={title} />
            </ScreenOneBox>
          </AppScreens>
          <DescBox className='desc'>
            <DescHeader>
              <h2>{title}</h2>
              <p className='highlight'>{category}</p>
            </DescHeader>
            <MDXRenderer>{project.body}</MDXRenderer>
            <h3 className='highlight role'>Role: {role}</h3>
            {/* <h3 className='highlight'>
							Link:{' '}
							<a href={link} target='_blank' rel='noopener noreferrer'>
								{link}
							</a>
						</h3> */}

            <Stack>
              <h3 className='highlight'>Technologies:</h3>
              <ul>
                {techStack.map((stack, index) => (
                  <Tool key={stack}>
                    {stack}
                    {isLastIndex(stack, index) ? '' : ','}
                  </Tool>
                ))}
              </ul>
            </Stack>
          </DescBox>
        </CardInner>
      </Container>
    </StyledPortfolioCard>
  )
}

export default PortfolioCard
